<template>
	<div>
		<b-card>
			<b-row>
				<b-col cols="12" lg="3">
					<div class="text-center">
						<h4>Total Saldo Balance IAK</h4>
						<p><strong>{{ formatRupiah(balance_saldo.total) }}</strong></p>
					</div>
				</b-col>
				<b-col cols="1">
					<div class="gap d-none d-lg-block ms-5"></div>
				</b-col>
				<b-col cols="12" lg="3">
					<div class="text-center">
						<h4>Total Points Masterumah</h4>
						<p><strong>{{ formatPoint("id", "IDR", total_point.total) }}</strong></p>
					</div>
				</b-col>
			</b-row>
		</b-card>
		<b-card title="Points List">
			<b-row class="mb-3">
				<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="show">Creator :</label>
					<v-select
						id="show" 
						v-model="filter.creator_id" 
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="creator_name"
						placeholder="Pilih / Cari Creator"
						:options="creatorList"
						:reduce="(creatorList) => creatorList.idcreator"
						@search="getContentSuggest"
						:clearable="true"
						:searchable="true"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Date:</label>
					<flat-pickr
						v-model="filter.date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
						maxDate: (() => {
							let date = new Date();
							date.setDate(date.getDate() - 1);
							return date;
						})(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

				<b-col cols="12" class="mt-2">
					<button class="btn btn-outline-secondary mr-2" @click="clearFilter">
						Reset
					</button>
					<button
						class="btn btn-primary"
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						v-b-modal.form-modal-export-csv
						@click="getDataExport">
							Export
						</button>
				</b-col>
				<Export
					:is-loading="isLoadingExport"
					:result-export="resultExport"
					:export-now="exportNow"
				/>
			</b-row>
			<Table :result="result" :feedback="feedback" :is-loading="isLoading" :get-data="getData" :edit-item="editItem"
				:format-date="formatDate" :current-page="currentPage" />
				<Update
				:is-loading="isLoading"
				:edit-id="editId"
				:validations="validations"
				:update-item="updateItem"
				:clean-up-form="cleanUpForm"
				:form-payload="formPayload"
				@payload="getPayload($event)"
			/>
		</b-card>
	</div>
</template>

<script>
import Export from '@/components/ExportCsv.vue'
import Table from '@/components/points/table.vue'
import Update from '@/components/points/Update.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import {
	BRow,
	BCol,
	BCard,
	BTable,
	BFormInput,
	BButton,
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		Update,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			balance_saldo: [],
			total_point: [],
			api: {
				// feedback_type: '/admin/feedbacks-jenis',
				feedback_list: '/admin/rewards-points',
				feedback_export: '/admin/export/feedback',
				balance_points: '/admin/rewards-points/balance',
				total_points: '/admin/rewards-points/sum-points',
				creator_export_list: '/admin/export/points/list',
				creator_export_data: '/admin/export/points/export',
				list_creator: '/admin/get-list-creator'
			},
			currentPage: 1,
			formPayload: Object,
			editId: null,
			validations: '',
			isLoading: false,
			result: [],
			feedback: {},
			list_feedback_type: [],
			link: '',
			isLoadingExport: false,
			resultExport: [],
			filter: {
				creator_id: '',
				date: ''
			},
			creatorList: [],
			today: new Date()
		}
	},
	watch: {
		filter: {
			handler: _.debounce(async function () {
				await this.getData();
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	async created() {
		await this.getData();
		await this.getDataBalance();
		await this.getDataTotalPoint();
		await this.getContentSuggest();
	},
	methods: {
		formatRupiah(value) {
			return new Intl.NumberFormat("id", { style: "currency", currency: "IDR", maximumFractionDigits: 0 }).format(value)
		},
		formatPoint(locale, currency, number) {
			return new Intl.NumberFormat(locale, {
				style: 'currency', maximumFractionDigits: 0,
				currency,
				currencyDisplay: "code"
			})
				.format(number)
				.replace(currency, "")
				.trim();
		},
		async getDataBalance() {
			await this.$http.get(this.api.balance_points).then(response => {
				this.balance_saldo = response.data.data
			}).catch(error => {
				return errorNotification(this, `error get data total balance:`, error)
			})
		},
		async getDataTotalPoint() {
			await this.$http.get(this.api.total_points).then(response => {
				this.total_point = response.data.data
			}).catch(error => {
				return errorNotification(this, `error get data total balance:`, error)
			})
		},
		async getDataExport() {
			this.isLoadingExport = true
			await this.$http.get(this.api.creator_export_list
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
				return errorNotification(this, 'error send export request :', error)
			})
		},
		clearFilter() {
			this.filter = {
				creator_id: '',
				date:''
			}
		},
		exportNow() {
				// this.isLoadingExport = true
				const exportParam = this.filter
				exportParam.per_page = null
				this.$swal({
							title: 'Sukses',
							icon: 'success',
							text: 'Mengexport data di background, mohon tunggu beberapa saat.',
							type: 'warning',
							customClass: {
								confirmButton: 'btn btn-primary',
							},
							buttonsStyling: false,
						}) 
				this.$bvModal.hide('form-modal-export-csv')
				this.$http.post(this.api.creator_export_data, exportParam).then(response => {
					if(response) {
						// this.$swal({
						// 	title: 'Sukses',
						// 	icon: 'success',
						// 	text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						// 	type: 'warning',
						// 	customClass: {
						// 		confirmButton: 'btn btn-primary',
						// 	},
						// 	buttonsStyling: false,
						// }) 
						console.log("success")
						// this.isLoadingExport = false
					}
				}).catch(error => {
					if (error.response.data.meta.validations) {
						this.validations = error.response.data.meta.validatiosns
					}
					return errorNotification(this, 'error Export', "Terjadi Kesalah Saat Export")	
				})
			},
		async getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.page = page
			await this.$http.get(this.api.feedback_list, {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.feedback = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})
		},
		async getContentSuggest(name){	
             await this.$http.get(this.api.list_creator, {
				params: {
					search:name
				}
			 }).then(response => {
				this.creatorList = response.data.data
			 })
		},
		cleanUpForm() {
			this.editId = null
			this.formPayload = {}
			this.validations = ''
		},
		editItem(item) {
			this.cleanUpForm()
			this.editId = item.creator.idcreator
			this.formPayload = {
				thread_points: 0,
				thread_message: ""
			}
			this.$bvModal.show('form-modal-appreciation')
		},
		updateItem(item) {
			const form = new FormData()
			form.append('creator_id', this.editId)
			form.append('points', item.thread_points)
			form.append('message', item.thread_message)	
			console.log(item)
			// form.append("_method", 'PUT')
			this.isLoading = true
			this.$http.post("/admin/rewards-points/apresiasi-creator/store", form)
			.then(response => {
				this.$bvModal.hide('form-modal-appreciation')
				this.getData()
				successNotification(this, 'Success', 'Apresiasi Points successfully updated!')
				this.cleanUpForm()
				this.isLoading = false
			}).catch(error => {
				if (error.response.data.meta.message) {
					this.validations = error.response.data.meta.message
				}
				this.isLoading = false
			})
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.gap {
	width: 1px;
	height: 55px;
	background: black;
}
</style>