<template>
  <b-col lg="4" class="mb-lg-0">

    <b-modal
      id="form-modal-appreciation"
      no-close-on-backdrop
      size="lg"
      title="Form Apresiasi Kreator"
    >
      <b-form>
        <b-row class="row">
          <b-col lg="12">
            <div class="form-group">
              <label for="">Points:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadAppreciation.thread_points"
                  placeholder="add appreciation points"
                  type="number"
                  required
                />
              </validation-provider>
            </div>
          </b-col>
          <b-col lg="12">
            <div class="form-group">
              <label for="">Message :</label>
              <br>
              <validation-provider>
                <b-form-textarea
                  v-model="formPayloadAppreciation.thread_message"
                  placeholder="Message"
                  required
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer="{}">
        <div v-if="isLoadingAsk">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingAsk"
          @click="updateItem(formPayloadAppreciation)"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </b-col>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BForm, BRow, BCol, BFormTextarea, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    vSelect,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    BInputGroup,
  },
  directives: {
    'height-fade': heightFade,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    category: {
      type: Array,
    },
    subCategory: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: Number,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    cleanUpForm: {
      type: Function,
    },
    inputSubCategory: {
      type: Function,
    }
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingAsk: false,
      formPayloadAppreciation: {
        thread_points: 0,
        thread_message : '',
      },
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingAsk = value
    },
    validations(value) {
      this.validationsMessage = value
    },
    // category: {
    //   handler(value) {
    //     this.categoryOptions = value
    //   },
    //   deep: true
    // },
    // subCategory: {
    //   handler(value) {
    //     this.subCategoryOptions = value
    //   },
    //   deep: true
    // },
    // formPayload: {
      handler(value) {
        this.formPayloadAppreciation.thread_points = value.thread_points
        this.formPayloadAppreciation.thread_message = value.thread_message
      },
      deep: true,
    },
    formPayloadAppreciation: {
      handler(value) {
        this.$emit('payload', value)
      },
      deep: true,
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
